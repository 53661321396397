import Vue from "vue";
import { addUrlParam } from "@/utils/utils";
Vue.prototype.$func = {
  // 打开窗口函数，id为窗口id，addParam为附加参数 [{param: 'key', value: '123'}]
  windowOpen: function (id, addParam = []) {
    // 获取当前地址
    var currentUrl = window.location.href;

    var firstQuestionMarkIndex = currentUrl.indexOf("?");
    var previousSlashIndex = currentUrl.lastIndexOf(
      "/",
      firstQuestionMarkIndex
    );

    // 确保找到了 `/` 和 `?`
    if (firstQuestionMarkIndex !== -1 && previousSlashIndex !== -1) {
      // 获取第一个 `?` 和 `?` 的前一个 `/` 之间的内容
      var contentToReplace = currentUrl.substring(
        previousSlashIndex + 1,
        firstQuestionMarkIndex
      );

      // 构建新的 URL
      var newUrl = currentUrl.replace(contentToReplace, id);
      if (addParam) {
        for (let i = 0; i < addParam.length; i++) {
          const addParamItem = addParam[i];
          newUrl = addUrlParam(newUrl, addParamItem.param, addParamItem.value);
        }
      }
      // 使用 history.replaceState 更新地址栏，不刷新页面
      window.open(newUrl, "_blank");
    } else {
    }
  },
  // 根据对比值获取对应的颜色
  getColorByValue: function (numerator, denominator, colors = {green: "rgb(0, 255, 0)", yellow: "rgb(255, 255, 0)", red: "rgb(255, 0, 0)"}) {
    // 分子分母相等------ 大于0都是绿色，等于0都是红色
    // 分子分母不等，但是分子有值------分子黄色，分母绿色
    // 分子分母不等，但是分子无值为0------分子红色，分母绿色
    if (numerator === denominator) {
      if(numerator > 0){
        return colors.green;
      }else{
        return colors.red;
      }
    }
    if (numerator !== denominator) {
      if (numerator > 0) {
        return colors.yellow;
      } else if (numerator === 0) {
        return colors.red;
      }
    }
  },
};
