import Vue from 'vue'
import ElementUI from 'element-ui';
import Avue from '@smallwei/avue'
import '@smallwei/avue/lib/index.css'
import axios from './axios'
import 'element-ui/lib/theme-chalk/index.css';
import dataV from '@jiaminghi/data-view'
import router from './router.js';
import App from './App.vue'
import './styles/common.scss'
import {url, cloudUrl} from '@/config'
import '@/utils/es6'
import '@/mock/'
import scroll from 'vue-seamless-scroll'
import '@/utils/func'

Vue.use(scroll)
//导入主题文件
import '@/theme/index.js'

// 全局配置  WangCong 2021.12.22
import website from '@/config/website';

import moment from 'moment'
import 'moment/locale/zh-cn'
import "video.js/dist/video-js.css"; // 引入视频监控样式
import {parseTime, addOrReduceDate} from './utils/utils'
import Video from 'video.js'

//引入icon
import './assets/font_4084472_ijv6x5uoj2/iconfont.css'

Vue.prototype.$video = Video

window.moment = moment
Vue.prototype.$bus = new Vue() // 设置事件总线
import vueClipBoard from 'vue-clipboard2'
Vue.use(vueClipBoard)
import echartsUtil from "@/utils/echarts-util"

window.MyEcharts = echartsUtil


import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: "6bvwwj0ZMOaZfDlzr1NWytnfhGsB9WfV"
});
// import * as echarts from 'echarts';
// window.echarts = echarts;
Vue.config.productionTip = false
Vue.prototype.url = url;
window.cloudUrl = cloudUrl;

import {VueJsonp} from 'vue-jsonp'
Vue.use(VueJsonp)

Vue.prototype.parseTime = parseTime
Vue.prototype.addOrReduceDate = addOrReduceDate

// 加载website  WangCong 2021.12.22
Vue.prototype.website = website;
window.axios = axios;
Vue.use(Avue, {
    size: 'mini'
});


Vue.prototype.$website = window.$website;
Vue.use(ElementUI);
Vue.use(dataV)
Vue.prototype.sandBoxUrl = "http://218.61.160.75:9228/bimsandbox/index.html" // BIM看板连接
window._AMapSecurityConfig = {
	securityJsCode:'8b64064941870e59dd803c77f4c2df6f',
}
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
